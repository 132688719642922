/* Estilos PC */
.footer {
  background-image: url("../../../public/images/fondo_footer.png");
}

.txt-redes-sociales {
  font-family: "Roboto";
  font-size: 14px;
  font-style: italic;
  text-align: left;
  font-weight: 300;
}

.txt-redes-sociales a {
  color: #ffffff;
  text-decoration: none;
}

.txt-pag {
  font-family: "Roboto";
  color: #ffffff;
  font-size: 17px;
  text-align: left;
  text-decoration: none;
}

.container_sc-footer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 20px 0;
}

.txt-Copy {
  font-family: "Roboto";
  color: #ffffff;
  font-size: 16px;
  text-align: left;
  letter-spacing: 1px;
  margin: 0;
  padding-bottom: 13px;
  text-align: center;
  font-weight: 300;
  order: 3;
}

.logoKimochi {
  margin: 10px 0;
  height: 50px;
}

.logo-div {
  text-align: center;
  width: 100%;
}

@media (min-width: 992px) {
  .txt-redes-sociales {
    font-size: 15px;
  }
  .txt-pag {
    font-size: 15px;
  }

  .txt-Copy {
    font-size: 14px;
    margin-top: 30px;
  }

  .logoKimochi {
    margin: 0;
    height: 40px;
    margin-right: 80px;
  }

  .logo-div {
    text-align: right;
    width: 100%;
    float: left;
    margin-top: -100px;
  }
}
@media (min-width: 1400px) {
  .txt-redes-sociales {
    font-size: 15px;
  }

  .txt-pag {
    font-size: 17px;
  }

  .txt-Copy {
    font-size: 14px;
  }

  .logoKimochi {
    height: 40px;
  }

  .logo-div {
    width: 100%;
    margin-top: -90px;
  }
}
