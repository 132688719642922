.backgroundWall {
  display: flex;
  flex-direction: column;
  height:100vh;
}

.container {
  width: 100%;
  padding: 30px  20px 20px;
}

.container-footerWall {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.container-footerWall img {
  width: 40%;
}

.container-footerWall h1 {
  font-family: "Roboto";
  font-size: 28px;
  color: #ff3399;
  letter-spacing: 1px;
  margin: 0;
  margin-left: 10px;
}

.container-footerWall-line {
  color: #ff3399;
  background: #ff3399;
  height: 3px;
  margin: 0;
}

.container-bodyWall {
  margin: 30px 10px 0 10px;
  background: #faf2f7;
  padding: 20px;
  border-radius: 10px 10px 10px 10px;
  z-index: 10;
}

.container-bodyWall p.text {
  text-align: left;
  font-size: 13px;
  line-height: 15px;
}

.container-bodyWall p.text .link {
  color: #380249;
  text-decoration: underline;
}

.container-bodyWall p {
  text-align: center;
}

.container-bodyWall p.container-bodyWall-under18 {
  font-family: "Hind", sans-serif;
  font-size: 13px;
  color: #666666;
  margin: 10px auto;
}

.container-bodyWall a.container-bodyWall-button {
  padding: 7px 20px;
  border-radius: 20px;
  background-color: #ff3399;
  color: #fff;
  text-decoration: none;
  font-family: "Roboto";
  font-weight: bold;
  font-size: 14px;
}

@media (min-width: 992px) {
  .backgroundWall {
    background-image: url("../../../public/images/wall/background.png");
    height: 100vh;
    width: 100vw;
  }
  .container {
    width: 1000px;
    position: absolute;
    bottom: 8%;
    right: 13%;
    padding: 0;
  }
  .container-footerWall img {
    width: 170px;
  }

  .container-footerWall h1 {
    font-size: 48px;
    letter-spacing: 1px;
    margin: 0;
    margin-left: 20px;
  }
  .container-bodyWall {
    margin-left: 170px;
    margin-top: -20px;
    background: #faf2f7;
    padding: 20px;
    box-shadow: 10px 10px 5px 0px rgba(230, 230, 230, 1);
  }

  .container-bodyWall p.text {
    text-align: left;
    font-size: 22px;
    line-height: 28px;
  }

  .container-bodyWall p.container-bodyWall-under18 {
    font-size: 21px;
    margin: 10px auto;
  }
  
  .container-bodyWall a.container-bodyWall-button {
    padding: 7px 20px;
    border-radius: 20px;
    font-size: 20px;
  }
  
}
