/* Estilos PC*/
#games .container_sc {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #efd5e5;
  padding: 10px 0;
  margin-bottom: 20px;
}

.title {
  margin: 20px auto;
}

.logo {
  width: 50%;
  margin: 0 auto;
}

.imageImpar {
  width: 80%;
  margin: 0 auto;
  margin-top: -50px;
  order: 3;
}

.subTitle {
  margin: 0;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 28px;
  color: #ff3399;
  letter-spacing: 1px;
  text-align: center;
}

.horizontalLineImpar {
  margin: 0 auto;
  color: #ff3399;
  width: 90%;
  height: 3px;
  background-color: #ff3399;
}

.horizontalLinePar {
  margin: 0;
  color: #ff3399;
  width: 87%;
  height: 3px;
  background-color: #ff3399;
  margin-left: 16%;
  margin-right: 0%;
}

.description {
  margin: 0 30px;
  width: 90%;
  order: 2;
}

.subTitle2 {
  font-family: "Roboto";
  font-weight: 500;
  font-style: italic;
  font-size: 25px;
  color: #ff6699;
  line-height: 32px;
  text-align: center;
}

.storeButton {
  margin: 10px 0;
  padding: 5px 20px;
  border-radius: 20px;
  background-color: #ff3399;
  color: #fff;
  text-decoration: none;
  font-family: "Roboto";
  font-weight: bold;
  font-size: 15px;
  width: 100px;
  z-index: 30;
  display: grid;
  justify-content: center;
}

.storeIcon {
  height: 15px;
  display: table-cell;
  vertical-align: middle;
}

.imagePar {
  order: 3;
  width: 80%;
  right: 0;
  margin: 0;
  margin-top: -250px;
  -ms-transform: rotateZ(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateZ(180deg);
  transform: rotateY(180deg);
  z-index: 2;
}

.buttonsArea {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 20;
}

@media (min-width: 992px) {
  .imagePar {
    width: auto;
    margin-right: -100px;
    margin-top: 0px;
  }

  .imageImpar {
    margin: 0;
    margin-left: -100px;
    width: auto;
  }

  .container_sc {
    padding: 10px 0;
    margin-bottom: 30px;
  }
  .logo {
    width: auto;
    margin: 0;
  }

  .subTitle {
    margin: 0;
    margin-top: 50px;
    font-size: 27px;
    letter-spacing: 1px;
    text-align: left;
  }

  .horizontalLineImpar {
    margin: 0;
    margin-left: -20px;
    width: 90%;
  }

  .description {
    margin: 0 30px;
    width: 38%;
  }

  .subTitle2 {
    font-size: 25px;
    line-height: 32px;
    text-align: left;
  }

  .horizontalLinePar {
    margin: 0;
    color: #ff3399;
    width: 85%;
    height: 3px;
    background-color: #ff3399;
    margin-left: 16%;
    margin-right: 0%;
  }

  .buttonsArea {
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
  }

  .storeButton {
    margin: auto;
    padding: 7px 20px;
    border-radius: 20px;
    background-color: #ff3399;
    color: #fff;
    text-decoration: none;
    font-family: "Roboto";
    font-weight: bold;
    font-size: 20px;
    width: 150px;
  }

  .storeIcon {
    height: 20px;
    display: table-cell;
    vertical-align: middle;
  }

  .imagePar,
  .logo-Impar {
    order: 1;
  }
  .logo-Par,
  .imageImpar {
    order: 3;
  }
  .alinearDerecha {
    text-align: right;
  }
}
@media (min-width: 1400px) {
  .container_sc {
    padding: 20px 0;
    margin-bottom: 20px;
  }

  .title {
    margin: 40px auto;
  }

  .subTitle {
    margin-top: 60px;
    font-size: 42px;
  }

  .description {
    margin: 0 30px;
    width: 40%;
  }

  .subTitle2 {
    font-size: 35px;
  }

  .storeButton {
    font-size: 25px;
    width: 180px;
  }

  .storeIcon {
    height: 25px;
  }
}
