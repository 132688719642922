/* You can add global styles to this file, and also import other style files */
body {
  margin: 0px;
}

.title {
  text-align: center;
  font-family: "Roboto";
  font-weight: bold;
  font-size: 45px;
  color: #380249;
  letter-spacing: 1px;
  font-style: italic;
}

.text {
  margin: 10px 0;
  font-family: "Hind", sans-serif;
  font-size: 18px;
  color: #380249;
  letter-spacing: 1px;
  line-height: 18px;
  text-align: center;
}

.highlightedText {
  color: #ff3399;
  font-weight: bold;
}

@media (min-width: 992px) {
  .title {
    font-size: 60px;
  }

  .text {
    font-size: 18px;
    line-height: 25px;
    text-align: justify;
  }
}
@media (min-width: 1400px) {
  .title {
    font-size: 90px;
  }

  .text {
    font-size: 23px;
    line-height: 32px;
  }
}
