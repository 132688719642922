/* Estilos PC */
.descriptionAboutUs {
    width: 95%;
    margin: auto;
    height: auto;
}
.subTitle {
    font-family: "Roboto";
    font-size: 28px;
    font-weight: bold;
    color: #ff3399;
    text-align: center;
}

.container_sc {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.mob {
    width: 98px;
    text-align: center;
}

.mob img {
    width: 80px;
    margin: 0 auto;
}

.mobText {
    font-family: "Roboto";
    font-size: 14px;
    font-style: italic;
    color: #ff6699;
    text-align: center;
}

@media (min-width: 992px) {
    .descriptionAboutUs {
        width: 60%;
        margin: auto;
        height: auto;
    }
    .subTitle {
        font-size: 30px;
    }
    .mob {
        width: 250px;
        text-align: center;
    }
    .mobText {
        font-size: 22px;
    }

    .mob img {
        width: auto;
    }
}
@media (min-width: 1400px) {
    .descriptionAboutUs {
        width: 50%;
    }
    .subTitle {
        font-size: 35px;
    }
    .mob {
        width: 300px;
    }
}
