header {
  margin: 0px;
  padding: 10px 3px;
  background-color: #ffffff;
  width: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 80;
  transition: 0.5s;
}
.main-nav__icon-menu {
  height: 20px;
}

.topnav {
  overflow: hidden;
  width: 85%;
  margin: auto;
  align-items: center
}

.topnav a {
  float: right;
  display: block;
  color: #380249;
  text-align: center;
  padding: 0.5em;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 1px;
  text-decoration: none;  
}

.topnav a:hover {
  text-decoration: underline #ff3399 solid;
}

.topnav .icon {
  display: none;
}

.logoKimochi {
  height: 40px;
}
.navbar-inverse {
  background-color: #380249;
}
.navbar-inverse a {
  color: #fff;
}

@media screen and (max-width: 600px) {
  .topnav a {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
  }
  .topnav.responsive {
    position: relative;
  }
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}

@media (min-width: 992px) {
  header {
    padding: 15px;
  }
  .main-nav__icon-menu {
    display: none;
  }
  .main-nav-list {
    display: block;
  }
  .main-nav ul {
    margin: 5px;
    text-align: left;
  }
  .main-nav li {
    display: inline;
  }
  .main-nav a {
    font-size: 18px;
  }
  .logoKimochi {
    height: 50px;
  }
}

@media (min-width: 1400px) {
  header {
    padding: 15px;
  }
  .main-nav ul {
    margin: 1em 0 0.5em;
  }
  .main-nav a {
    padding: 0.5em 1.5em;
    font-size: 23px;
  }
  .logoKimochi {
    height: 60px;
  }
}
