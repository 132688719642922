/* Estilos PC */
.descriptionJoinCommunity {
  width: 90%;
  margin: auto;
}
.subTitle {
  font-family: "Roboto";
  font-size: 28px;
  font-weight: bold;
  color: #ff3399;
  letter-spacing: 1px;
  text-align: center;
}
.container_sc-community {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 20px 0;
}
.container--sc{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px 0;
}

.container_scFeed {
  width: 300px;
  height: 500px;
  background-color: #efd5e5;
  border-radius: 15px;
  margin: 15px auto;
}
.subTitle2-community {
  font-family: "Hind", sans-serif;
  font-size: 23px;
  font-weight: 500;
  color: #380249;
  letter-spacing: 1px;
  text-align: center;
}
.redesSociales {
  width: 250px;
  margin: 0 auto;
}
.supportPatreon {
  width: 80%;
  margin: 0 auto;
}
.supportPatreon img {
  width: 100%;
  transition: all 0.4s ease;
  margin-bottom: 20px;
}

.supportPatreon img:hover {
  transform: scale(1.2);
}

@media (min-width: 992px) {
  .descriptionJoinCommunity {
    width: 60%;
  }
  .subTitle {
    font-size: 30px;
  }
  .subTitle2-community {
    font-size: 30px;
  }
  .redesSociales {
    width: 300px;
    margin: 0 auto;
  }
  .supportPatreon {
    width: auto;
  }
  .supportPatreon img {
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .container_scFeed {
    margin: 0 50px;
  }
}
@media (min-width: 1400px) {
  .descriptionJoinCommunity {
    width: 50%;
  }
  .subTitle {
    font-size: 50px;
  }
  .subTitle2-community {
    font-size: 35px;
  }
  .redesSociales {
    width: 400px;
  }
  .container_scFeed {
    margin: 0 100px;
  }
}
