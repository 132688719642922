.container--privacy{
  margin: 0;
  width: 100%;
}
.container--header {
  width: 100%;
  text-align: center;
  background-color: #380249;
  color: white;
  font-family: "Roboto";
  font-weight: bold;
  letter-spacing: 1px;
  font-style: italic;
  padding: 11px 0%;
}
.container--header h1 {
  font-size: 28px;
}

.container--terms {
  margin: 0 auto;
  width: 90%;
  padding: 20px;
}

.container--terms h2 {
  font-family: "Roboto";
  font-size: 22px;
  font-weight: bold;
  color: #ff3399;
  text-align: center;
  padding: 13px;
  text-transform: uppercase;
}

.container--terms .link {
  color: #ff3399;
  font-weight: bold;
  text-decoration: underline;
}

@media (min-width: 992px) {
  .container--header {
    font-size: 45px;
    letter-spacing: 1px;
    padding: 1.5% 0%;
  }

  .container--header h1 {
    font-size: 45px;
  }

  .container--terms {
    width: 60%;
    padding: 20px;
  }

  .container--terms h2 {
    font-size: 35px;
    padding: 20px;
  }
}
